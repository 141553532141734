const servicesActionTypes = {
  LOAD: "SERVICES_LOAD",
  LOAD_SUCCEEDED: "SERVICES_LOAD_SUCCEEDED",
  LOAD_FAILED: "SERVICES_LOAD_FAILED",

  UPDATE: "UPDATE_SERVICES",
  UPDATE_SUCCEEDED: "UPDATE_SERVICES_SUCCEEDED",
  UPDATE_FAILED: "UPDATE_SERVICES_FAILED",

  SAVE: "SAVE_SERVICE",
};

export default servicesActionTypes;
