const shopActionTypes = {
  LOAD: "SHOPS_LOAD",
  LOAD_SUCCEEDED: "SHOPS_LOAD_SUCCEEDED",
  LOAD_FAILED: "SHOPS_LOAD_FAILED",

  LOAD_SHOP: "SHOP_LOAD",
  LOAD_SHOP_SUCCEEDED: "SHOP_LOAD_SUCCEEDED",
  LOAD_SHOP_FAILED: "SHOP_LOAD_FAILED",

  UPDATE: "UPDATE_SHOP",
  UPDATE_SUCCEEDED: "UPDATE_SHOP_SUCCEEDED",
  UPDATE_FAILED: "UPDATE_SHOP_FAILED",

  SAVE: "SAVE_SHOP",
  SAVE_SUCCEEDED: "SAVE_SHOP_SUCCEEDED",
  SAVE_FAILED: "SAVE_SHOP_FAILED",
};

export default shopActionTypes;
